import Form, { formCreate } from '@/found/components/form';

import ProductRespVos from './product_info.vue';
import PhotoRespVos from './photo_list.vue';

formCreate.component('ProductRespVos', ProductRespVos);
formCreate.component('PhotoRespVos', PhotoRespVos);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'entry_fee_form',
    };
  },
  created() {

  },
  methods: {
    formComplete() {
      if (this.formConfig.code === 'view') {
        this.setValue(this.formConfig.row);
      }
    },
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productRespVos') {
        v.props.value = this.formConfig.row.productRespVos || [];
      }
      if (v.field === 'photoRespVos') {
        v.props.value = this.formConfig.row.PhotoRespVos || [];
      }
      return v;
    },
  },
};
